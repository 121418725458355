import { createSlice } from "@reduxjs/toolkit";

interface ModalState {
  modalState: string | boolean;
  id: string;
}

const initialState = {
  modalState: "",
  id: "",
} as ModalState;

const ModalSlice = createSlice({
  name: "modal",
  initialState,
  reducers: {
    setModalOpen: (state, { payload }) => {
      state.modalState = payload;
    },
    setModalId: (state, { payload }) => {
      state.id = payload;
    },
    closeModalId: (state) => {
      state.id = "";
      state.modalState = false;
    },
  },
});

export const { setModalId, setModalOpen, closeModalId } = ModalSlice.actions;
export default ModalSlice.reducer;
