export const PasswordOpen = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M17 12C17 12 14.76 15 12 15C9.24 15 7 12 7 12C7 12 9.24 9 12 9C14.76 9 17 12 17 12ZM12 3C10.22 3 8.47991 3.52784 6.99987 4.51677C5.51983 5.50571 4.36628 6.91131 3.68509 8.55585C3.0039 10.2004 2.82567 12.01 3.17294 13.7558C3.5202 15.5016 4.37737 17.1053 5.63604 18.364C6.89472 19.6226 8.49836 20.4798 10.2442 20.8271C11.99 21.1743 13.7996 20.9961 15.4442 20.3149C17.0887 19.6337 18.4943 18.4802 19.4832 17.0001C20.4722 15.5201 21 13.78 21 12C21 9.61305 20.0518 7.32387 18.364 5.63604C16.6761 3.94821 14.387 3 12 3Z"
        stroke="#C4C6CB"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M12.05 12H11.95" stroke="#C4C6CB" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export const Email = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M20 5H4C3.44772 5 3 5.44772 3 6V18C3 18.5523 3.44772 19 4 19H20C20.5523 19 21 18.5523 21 18V6C21 5.44772 20.5523 5 20 5Z" stroke="#C4C6CB" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M3 8L12 13L21 8" stroke="#C4C6CB" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export const LockSvg = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_16_246)">
        <path
          d="M12 17V16M17 10H7V8C7 6.67392 7.52678 5.40215 8.46447 4.46447C9.40215 3.52678 10.6739 3 12 3C13.3261 3 14.5979 3.52678 15.5355 4.46447C16.4732 5.40215 17 6.67392 17 8V10ZM18 10H6C5.73478 10 5.48043 10.1054 5.29289 10.2929C5.10536 10.4804 5 10.7348 5 11V14C4.99958 14.9372 5.18736 15.8649 5.5522 16.7282C5.91704 17.5915 6.45152 18.3727 7.12393 19.0255C7.79635 19.6783 8.59301 20.1895 9.46668 20.5287C10.3403 20.8678 11.2732 21.0281 12.21 21C14.0641 20.8969 15.8065 20.0804 17.0722 18.7216C18.3379 17.3627 19.0287 15.5668 19 13.71V11C19 10.7348 18.8946 10.4804 18.7071 10.2929C18.5196 10.1054 18.2652 10 18 10Z"
          stroke="url(#paint0_linear_16_246)"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path d="M11.9502 15H12.0502" stroke="url(#paint1_linear_16_246)" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
      </g>
      <defs>
        <linearGradient id="paint0_linear_16_246" x1="2.69986" y1="-0.125547" x2="25.3277" y2="18.6633" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FF3800" />
          <stop offset="1" stopColor="#EE7930" />
        </linearGradient>
        <linearGradient id="paint1_linear_16_246" x1="11.9338" y1="14.8264" x2="12.2037" y2="14.8552" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FF3800" />
          <stop offset="1" stopColor="#EE7930" />
        </linearGradient>
        <clipPath id="clip0_16_246">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
