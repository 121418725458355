import { closeModalId } from "features/modalSlice";
import { useAppDispatch } from "hooks/reduxHooks";
import React, { useEffect, lazy, Suspense } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import ForgotPassword from "screens/auth/ForgotPassword";
import Login from "screens/auth/Login";
import NotFound from "screens/auth/NotFound";
import ResetPassword from "screens/auth/ResetPassword";
const Dashboard = lazy(() => import("screens/dashboard/Dashboard"));
// import Dashboard from "screens/dashboard/Dashboard";

const Router = () => {
  const location = useLocation();
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(closeModalId());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <Suspense fallback={<h1>Loading</h1>}>
      <div className="h-full">
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/*" element={<NotFound />} />
          <Route path="/dashboard/*" element={<Dashboard />} />
        </Routes>
      </div>
    </Suspense>
  );
};

export default Router;
