import React from "react";
import { Toaster } from "react-hot-toast";
import { BrowserRouter as WebAppRouter } from "react-router-dom";
import Router from "router/Router";

function App() {
  return (
    <div className="h-full">
      <WebAppRouter>
        <Router />
      </WebAppRouter>
      <Toaster
        toastOptions={{
          // Define default options
          className: "",
          duration: 5000,
          style: {
            background: "#363636",
            color: "#fff",
            marginTop: "100px",
          },

          // Default options for specific types
          success: {
            duration: 5000,
          },
        }}
      />
    </div>
  );
}

export default App;
