import { combineReducers } from "@reduxjs/toolkit";
import { OyaQueryApi } from "api/QueryApi";
import AuthSlice from "features/authSlice";
import ModalSlice from "features/modalSlice";

export const rootReducer = combineReducers({
  auth: AuthSlice,
  modal: ModalSlice,
  [OyaQueryApi.reducerPath]: OyaQueryApi.reducer,
});
